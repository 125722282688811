import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { IProgramPlanner } from '@/Model/programModel';
import commonEntry from '@/store/modules/commonEntry';
import programPlanner from '@/store/modules/programPlannerStore';
import APP_UTILITIES from '@/utilities/commonFunctions';
import APP_CONST from '@/constants/AppConst';
import { ScreenText } from '@/lang/ScreenText';
import DatepickerComponent from '@/ui-components/datepicker/DatepickerComponent.vue';

@Component({
  components: {
    'datepicker': DatepickerComponent
  }
})
export default class taskupdateComponent extends Vue {
    public updateTask: IProgramPlanner = { ...APP_CONST.PROGRAM_PLANNER } as IProgramPlanner;
    validatedFields = APP_CONST.VALIDATE_PROG_PLAN_FORM;
    private objScreenText: ScreenText = new ScreenText();
    public enableDatesOnly: boolean = false;
    public allFieldRequired :boolean =false;
    public dateResponseError : boolean =false;
    public dateResponse :string ='';
    public dateType :string ='';

    @Prop()
    plannerTaskObj!: IProgramPlanner;

    get getUserDetail() {
      return commonEntry.userDetail;
    }


    beforeMount() {
      const user_id = APP_UTILITIES.getCookie(APP_CONST.USER_ID);
      if (user_id) {
        commonEntry.fetchUserDetails(parseInt(user_id));
      }
    }

    
    public getScreenText(key: string): string {
      return this.objScreenText.getScreenText(key);
    }

    @Watch('plannerTaskObj', { deep: true, immediate: true })
    bindUpdatedTask(data: any) {
      this.updateTask = { ...data };
      if(data.cmsTaskId > 0) {
        this.enableDatesOnly = true;
      }
      else {
        this.enableDatesOnly = false;
      }
    }

    updateProgramTask() {
      const ObjPlannerTask = { ...this.updateTask };
      const ValidateObj = this.validatedFields;
      const checkValidation = APP_UTILITIES.requiredFields(ObjPlannerTask, ValidateObj);
      this.validatedFields = { ...checkValidation.validateData };
      if (checkValidation.isFormInvalid) {
        this.allFieldRequired =checkValidation.isFormInvalid;
        return;
      }
      ObjPlannerTask.startDate = APP_UTILITIES.formatCorrectDate(ObjPlannerTask.startDate) as any;
      ObjPlannerTask.endDate = APP_UTILITIES.formatCorrectDate(ObjPlannerTask.endDate) as any;
      ObjPlannerTask.productProgramId = 1 as any;
      programPlanner.updateProgramPlannerTask(ObjPlannerTask).then(() => {
        if (programPlanner.updatedPlanTaskData.status === APP_CONST.RESPONSE_200) {
          this.$emit(APP_CONST.UPDATE_TASK, {ObjPlannerTask, state: 'modifyTask'});
          const programStartDate = this.updateTask.programStartDate;
          const programEndDate = this.updateTask.programEndDate;
          this.updateTask = { ...APP_CONST.PROGRAM_PLANNER } as any;
          this.updateTask.programStartDate = programStartDate;
          this.updateTask.programEndDate = programEndDate;
          this.plannerTaskObj.createdAt = new Date() as any;
          this.allFieldRequired = APP_CONST.FALSE;
          const scroll: any = document.getElementById('taskupdate-scroll');
          scroll.scrollTop = 0;
        }
      });
    }

    checkForCorrectDate(type: string) {
      let startDate = this.updateTask.startDate;
      let endDate = this.updateTask.endDate;
      this.dateType = '';
      this.dateResponseError =APP_CONST.FALSE;
      if (type === APP_CONST.END_DATE) {
        if (startDate.length === APP_CONST.ZERO) {
          this.dateResponseError =APP_CONST.TRUE;
          this.dateResponse = APP_CONST.FILL_START;
          this.allFieldRequired = APP_CONST.FALSE;
          this.dateType = 'startDate';
          setTimeout(() => {
            endDate = '';
            startDate = '';
            this.updateTask.startDate = '';
            this.updateTask.endDate = '';
          });
        }
        if (Date.parse(this.updateTask.endDate) < Date.parse(this.updateTask.startDate)) {
          this.dateResponseError =APP_CONST.TRUE;
          this.dateResponse = APP_CONST.ALERT_END_DATE;
          this.allFieldRequired = APP_CONST.FALSE;
          this.dateType = type;
          setTimeout(() => {
            endDate = ''; this.updateTask.endDate = ''; 
          });
        }
      }
      else {
        if (Date.parse(this.updateTask.endDate) < Date.parse(this.updateTask.startDate)) {
          this.dateResponseError =APP_CONST.TRUE;
          this.dateResponse = APP_CONST.ALERT_END_DATE;
          this.allFieldRequired = APP_CONST.FALSE;
          this.dateType = 'endDate';
          setTimeout(() => {
            endDate = ''; this.updateTask.endDate = ''; 
          });
        } 
      }
      const programStartDate = new Date(Date.parse(this.updateTask.programStartDate));
      const programEndDate = new Date(Date.parse(this.updateTask.programEndDate));
      
    }

    cancelUpdation() {
      this.plannerTaskObj.createdAt = new Date() as any;
      this.$emit(APP_CONST.CLOSE);
      this.allFieldRequired = APP_CONST.FALSE;
      this.dateResponseError = APP_CONST.FALSE;
      this.dateResponse = '';
      this.dateType = '';
      const scroll: any = document.getElementById('taskupdate-scroll');
      scroll.scrollTop = 0;
    }

    back() {
      this.allFieldRequired = APP_CONST.FALSE;
      this.dateResponseError = APP_CONST.FALSE;
      this.dateResponse = '';
      this.dateType = '';
      this.$emit(APP_CONST.BACK_BUTTON);
    }

}